@use 'style/colors';
@use 'style/fonts';
@use 'services/Viewport';

body {
  margin: 0;
  font-family: fonts.get-font-family($family: 'Lato');
  word-break: break-word;
  color: #373640;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  -ms-text-size-adjust: 100%;
  -moz-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

* {
  box-sizing: border-box;
}

:visited {
  color: unset;
}

a {
  color: unset;
  text-decoration: none;

  &:-webkit-any-link,
  &:visited {
    color: unset;
  }
}

.app {
  min-height: calc(Viewport.getVHVariable() * 100);

  &__preloader {
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
}

// NOTE hack to prevent document shifting when scrollbar appears
html {
  position: relative;

  overflow-x: hidden;
  margin-right: calc(-1 * (100vw - 100%));
  font-variant-numeric: lining-nums;
}

button {
  color: black; // fix blue text in button for safari IOS 15
}
